import PropTypes from 'prop-types';
import api from '../../services/api';

import ConfirmModal from '../ConfirmModal';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ProModal = ({ onClose, isOpen }) => {
  async function handleProModalConfirm() {
    try {
      const { data } = await api.post('/request_plan');
      window.location.href = data.paymentLink || '';
      onClose();
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
      } else {
        Notification({
          message: 'Erro ao gerar link de pagamento',
          type: Notification.types.ERROR,
        });
      }
    }
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleProModalConfirm}
      text={
        'Ao realizar a assinatura Pro você terá acesso a todos os ' +
        'projetos marcados como Pro. \n\nO valor da assinatura é de R$20,00 mensais.' +
        '\n\nDeseja realizar a assinatura?'
      }
      title="Realizar assinatura PRO"
    />
  );
};

ProModal.propTypes = propTypes;

export default ProModal;
