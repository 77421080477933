import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import Button from '../../components/Button';
import Notification from '../../components/Notification';
import ModalWithInput from '../../components/ModalWithInput';
import api from '../../services/api';
import { copyToClipboard } from '../../utils/Text';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  Text,
  ButtonsContainer,
  Headline,
  modalStyles,
  CopyButton,
  IndicationItem,
} from './styles';
import AlertModal from '../../components/AlertModal';

function IndicateFriend() {
  const { loading: authLoading } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [indications, setIndications] = useState([]);
  const [selectedIndication, setSelectedIndication] = useState({});

  async function fetchIndications() {
    try {
      const response = await api.get('/indications');
      setIndications(response.data);
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
        return;
      }

      Notification({
        message: 'Erro ao buscar links',
        type: Notification.types.ERROR,
      });
    }
  }

  useEffect(() => {
    (async () => {
      if (authLoading) return;
      await fetchIndications();
    })();
  }, [authLoading]);

  async function handleSubmit(value) {
    try {
      setLoading(true);
      const postData = {};
      if (value) {
        postData.customToken = value;
      }
      const response = await api.post('/indications', postData);
      setInviteLink(response.data.link);
      setModalOpen(true);
      fetchIndications();
    } catch (err) {
      let message = 'Erro ao gerar link';
      if (err.response) {
        if (err.response.data.errors) {
          message = err.response.data.errors.customToken;
        } else {
          message = err.response.data.error;
        }
      }

      Notification({
        message,
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  function handleCloseModal() {
    setModalOpen(false);
  }

  function handleCopyButtonClick() {
    copyToClipboard(inviteLink);
    Notification({ message: 'Copiado com sucesso' });
  }

  function handleCustomModalOpen() {
    setCustomModalOpen(true);
  }

  function handleCustomModalClose() {
    setCustomModalOpen(false);
  }

  function handleConfirmCustoModal(value) {
    setCustomModalOpen(false);
    handleSubmit(value);
  }

  function handleDeleteLinkButtonClick(indication) {
    setSelectedIndication(indication);
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
  }

  async function handleDeleteModalConfirm() {
    try {
      setLoading(true);
      await api.delete(`/indication/${selectedIndication.id}`);
      Notification({
        message: 'Link excluído com sucesso',
        type: Notification.types.SUCCESS,
      });
      fetchIndications();
    } catch (err) {
      const message = err.response
        ? err.response.data.error
        : 'Erro ao excluir link';

      Notification({
        message,
        type: Notification.types.ERROR,
      });
    } finally {
      handleDeleteModalClose();
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <ModalWithInput
        isOpen={isCustomModalOpen}
        onClose={handleCustomModalClose}
        onConfirm={handleConfirmCustoModal}
        title="Criar link personalizado"
        text="Digite o que deve para aparecer no final do link, ficando assim: portal.duelaser.com/register?t=meu-link-personalizado"
        inputType="text"
      />
      <AlertModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        title="Excluir link de indicação"
        text={`Deseja realmente excluir o link: ${selectedIndication.link}`}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Link de convite"
        style={modalStyles}
        closeTimeoutMS={500}
      >
        <Headline>Link de convite</Headline>
        <Text>{inviteLink}</Text>
        <CopyButton type="button" onClick={handleCopyButtonClick}>
          <Text>Copiar</Text>
        </CopyButton>
        <ButtonsContainer>
          <Button text="Fechar" primary onClick={handleCloseModal} />
        </ButtonsContainer>
      </Modal>
      <Container>
        <div>
          <Text>
            Indique um amigo e receba crédito caso ele complete o cadastro
          </Text>
          <Button
            onClick={() => handleSubmit()}
            primary
            text="Gerar link aleatório"
          />
          <Button
            onClick={handleCustomModalOpen}
            primary
            text="Gerar link personalizado"
          />
        </div>
        {indications && indications.length > 0 && (
          <Headline>Links gerados</Headline>
        )}
        {indications &&
          indications.map(i => (
            <IndicationItem>
              <div>
                <p>
                  <strong>Criado em:</strong>{' '}
                  {new Date(i.createdAt).toLocaleDateString('pt-br')}
                </p>
                <p>
                  <strong>Número de registros:</strong> {i.registers}
                </p>
                <p>
                  <strong>Link:</strong> {i.link}
                </p>
              </div>
              <div>
                <Button
                  text="Copiar link"
                  onClick={() => copyToClipboard(i.link)}
                />
                <Button
                  text="Excluir link"
                  color="red"
                  onClick={() => handleDeleteLinkButtonClick(i)}
                />
              </div>
            </IndicationItem>
          ))}
      </Container>
    </>
  );
}

export default IndicateFriend;
